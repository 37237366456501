
import { mapGetters, mapActions } from 'vuex';
import { i18n, getLanguageCode } from '@/i18n';


export default {
  name: 'app-settings-list-table',
  props:['rows'],

  async created() {
    await this.doFindSettings();
  },
  data() {
    return {
      filter:'',
      confirm:false,
      columns: [
        {
          name: 'title',
          field: 'title',
          label: i18n('settings.fields.barcodeTitle'),
          align: 'center',
          format: (val) => `${val}`,
          required: true,
        },
        {
          name: 'barcodeName',
          field: 'barcodeName',
          label: i18n('settings.fields.barcodeName'),
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'barcodeTitle',
          field: 'barcodeTitle',
          label: i18n('settings.printedTitle'),
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'time',
          field: 'time',
          label: i18n('settings.updateTime'),
          align: 'center',
          required: true,
          sortable: true,
        },
        {
          name: 'date',
          field: 'date',
          label: i18n('settings.updateDate'),
          align: 'center',
          required: true,
          sortable: true,
        },

        {
          name: 'action',
          field: 'action',
          label: i18n('common.actions'),
          align: 'center',
        },
      ],
      columns2: [
        {
          name: 'title',
          field: 'title',
          label: i18n('settings.fields.barcodeTitle'),
          align: 'center',
          format: (val) => `${val}`,
          required: true,
        },
        {
          name: 'barcodeName',
          field: 'barcodeName',
          label: i18n('settings.fields.barcodeName'),
          align: 'center',
          format: (val) => `${val}`,
          required: true,
        },
        {
          name: 'barcodeTitle',
          field: 'barcodeTitle',
          label: i18n('settings.printedTitle'),
          align: 'center',
          format: (val) => `${val}`,
          required: true,
        },
      ],
      language: getLanguageCode(),
      dialogVisible: false,
      printModalVisible: false,
      printDialogVisible: false,
      dialogType: '',
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
    };
  },
  computed: {
    ...mapGetters({
      loading: 'settings/loading',
      is_screen_xs: 'layout/is_screen_xs',
      settings: 'settings/settings',
      saveLoading: 'settings/saveLoading',
      findLoading: 'settings/findLoading',
    }),
    isLoading(){
      return this.saveLoading || this.loading
    },
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    }
  },

  methods: {
    ...mapActions({
      doFindSettings: 'settings/doFind',
      doSave: 'settings/doSave',
    }),
    closeDialog(){
      this.confirm = false
    },
    openDialog(id){
      this.selectedId = id
      this.confirm = true
    },
    doDelete(id){
      this.$emit('delete',id)
    },
    doEdit(row){
      this.$emit('update',row);
    },
    doApply(row){
      this.settings.title = row.title
      this.settings.barcodeName = row.barcodeName
      this.settings.barcodeTitle = row.barcodeTitle
      return this.doSave(this.settings)
    },
    i18n(code) {
      return i18n(code)
    },

  },
};
